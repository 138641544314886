.btn_trash{
    background: no-repeat;
    border: 0;
    font-size: 15px;
}
.txtdis{
    width: 78px;
    float: right;
    height: 35px;
}
.nav-pills:not(.nav-pills-circle) .nav-item{
    padding-right: 15px;
}
.card-body{
    cursor: pointer;
}
.footercss{
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background: lightskyblue;
    padding: 1.5rem 0;
}
.bg_headercss{
   background-color: #87cefa;
}
.navbar-vertical .navbar-nav .nav-link.active:before{
    border-left: 2px solid #87cefa;
}
.page-item.active .page-link {
    background-color: #87cefa;
    border-color: #87cefa; }

    .navbar-vertical .navbar-nav .nav-link{
        font-size: 17px;
    }
.new_line {
  white-space: pre-line;
}
.login_title{
    font-size: 22px;
    font-weight: bold;
    display: block;
}
.logincard{
    padding-top: 5%;
    padding-bottom: 0%;
    border-bottom: 0;
}
.logouticon{
    font-size: 25px;
    cursor: pointer;
    line-height: 1.5;
}
.brandtext{
    font-size: 19px;
    font-weight: bold;
    color: #000;
}
.visitor_card{
    padding: 8px 15px;
}
.v_label{
    padding-left: 7px;
    vertical-align: middle;
}
.navbar-brand{
    width: 99%;
}
.edit_icon{
    font-size: 10px;
    padding: 4px;
    border-radius: 3px;
    vertical-align: top;
}
@media (max-width: 767.98px){
.nav-pills:not(.nav-pills-circle) .nav-item {
    padding-right: 15px;
}
.navbar-brand{
    width: 80%;
}
}
.pagination {
    display: inherit;
}  
.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    background-color: rgba(255, 255, 255, 0.815);
    border: 1px solid #5e72e4;
    margin: 0 2px;
  }
  .pagination li.active a{
    background-color: #5e72e4;
    color: white;
    border: 1px solid #5e72e4;
  }
.pagination a:hover:not(.active) {background-color: #ddd;}
.pagination a:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}  
.pagination a:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
